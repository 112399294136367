import "./App.css";
import logo from "./assets/luniwave.png";
import luniwaveLogo from "./assets/logo_rond.png";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import AddHotel from "./pages/AddHotel";
import AddFloor from "./pages/Deprecated/AddFloor";
import AddRoom from "./pages/Deprecated/AddRoom";
import Alertes from "./pages/Alertes/Alertes";
import RoomAlerts from "./pages/Alertes/RoomAlerts";
// import Statistiques from "./pages/Statistiques";

import HotelPage from "./pages/HotelPage";
import FloorPage from "./pages/Deprecated/FloorPage";
import RoomPage from "./pages/RoomPage";
import RoomDetails from "./pages/Deprecated/RoomDetails";
//import { useKeycloak } from "@react-keycloak/web";
import { useAuth } from "react-oidc-context";
import CompleteHotel from "./pages/Deprecated/ModifHotel/CompleteHotel";
import GestionMeshForHotel from "./pages/Deprecated/ModifHotel/GestionMeshForHotel";
import GestionRoomsForHotel from "./pages/Deprecated/ModifHotel/GestionRoomsForHotel";
import Mesh from "./pages/Deprecated/MeshPage";
import AlertDetails from "./pages/Alertes/AlertDetails";
import ModulePage from "./pages/ModulePage";
import SyncShowersPage from "./pages/SyncShowersPage";
import HotelAlertsPage from "./pages/HotelAlertsPage";

function App() {
  const auth = useAuth();

  const login = () => {
    auth.signinRedirect();
  };

  const logout = () => {
    auth.signoutRedirect();
  };

  if (auth.isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <img src={luniwaveLogo} alt="Luniwave Logo" className="w-20 h-20 animate-spin mx-auto" />
          <p className="mt-4 text-xl">Chargement en cours...</p>
        </div>
      </div>
    );
  }

  // if (!initialized) {
  //   return <div>Loading...</div>;
  // }

  if (!auth.isAuthenticated) {
    console.log("PUBLIC_KEYCLOAK_HOSTNAME", process.env.REACT_APP_KC_URL);
    console.log("REDIRECT_URL", process.env.REACT_APP_REDIRECT_URL);
    console.log("API_URL", process.env.REACT_APP_API_URL);
    return (
      <div className="min-h-screen bg-[#98B77F] relative flex items-center justify-center">
        <img src={logo} alt="Logo" onClick={() => console.log(auth)} className="absolute top-4 left-4 max-w-[150px] h-auto" />
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h1 className="text-2xl font-bold mb-4">Se connecter à LuniManager</h1>
          <button onClick={login} className="bg-[#365644] text-white py-2 px-4 rounded hover:bg-[#2d4c3c] transition duration-300">
            Se connecter
          </button>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/addHotel" element={<AddHotel />} />
        <Route exact path="/addRoom" element={<AddRoom />} />
        <Route exact path="/hotels/:id" element={<HotelPage />} />
        <Route exact path="/hotels/:hotel_id/rooms/:id" element={<RoomPage />} />
        <Route exact path="/hotels/:hotel_id/modules/:id" element={<ModulePage />} />
        <Route path="/hotels/:hId/syncShowers" element={<SyncShowersPage />} />
        <Route path="/hotels/:hid/alerts" element={<HotelAlertsPage />} />

        {/* DEPRECATED */}
        {/* <Route path="/addFloor" element={<AddFloor />} /> */}
        {/* <Route exact path="/hotels/:id/complete" element={<CompleteHotel />} /> */}
        {/* <Route exact path="/hotels/:id/meshs" element={<GestionMeshForHotel />} /> */}
        {/* <Route exact path="/hotels/:id/editrooms" element={<GestionRoomsForHotel />} /> */}
        {/* <Route exact path="/hotels/:hotel_id/floors/:floor_id" element={<FloorPage />} /> */}
        {/* <Route path="/alerts/rooms/:roomId" element={<RoomDetails />} /> */}
        {/* <Route path="/meshs" element={<Mesh />} /> */}
        {/* <Route path="/stats" element={<Statistiques />} /> */}

        {/* NOT DEPRECATED BUT TBD  */}
        {/* <Route path="/alertes" element={<Alertes />} /> */}
        {/* <Route path="/hotels/:hotel_id/rooms/:room_id/alerts" element={<RoomAlerts />} />
        <Route path="/hotels/:hotel_id/rooms/:room_id/alerts/:id" element={<AlertDetails />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
