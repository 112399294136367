import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Floor } from "../components/Floor/Floor";
import { Room } from "../components/Room/Room";
import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Carrousel } from "../components/Hotel/Carroussel_Hotel";
import { Module } from "../components/Module/Module";
import { EditRoomModal } from "../components/Room/EditRoomModal";

function HotelPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [listeChambre, setListeChambre] = useState([]);
  const [listeReserve, setListeReserve] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalGatewayOpen, setIsModalGatewayOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("rooms");
  const [hotel, setHotel] = useState(null);
  const [consumption, setConsumption] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    stars_number: 0,
    heating_type: "",
    subscription_type: 0,
    content: "",
    is_visible: 1,
  });
  const [gateways, setGateways] = useState([]);
  const [newGateway, setNewGateway] = useState({
    name: "",
    internet_type: "",
    hotel_id: id,
  });

  // Nouveaux états pour le choix du mois et de l'année
  const currentDate = new Date();
  const defaultMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const defaultYear = currentDate.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [selectedYear, setSelectedYear] = useState(defaultYear);

  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(null);

  const auth = useAuth();
  const token = auth.user.access_token;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  useEffect(() => {
    // Récupération des chambres
    axios
      .get(`${API_URL}/hotels/${id}/rooms`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const sortedRooms = response.data.result.sort((a, b) => a.room_nb - b.room_nb);
        setListeChambre(sortedRooms);
      });

    // Récupération des modules en réserve
    axios
      .get(`${API_URL}/hotels/${id}/modules`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const modulesSansChambre = response.data.result.filter((module) => module.room_id === null);
        setListeReserve(modulesSansChambre);
      });

    // Récupération des infos de l'hôtel
    axios
      .get(`${API_URL}/hotels/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setHotel(response.data.result);
        setFormData({
          name: response.data.result.name || "",
          location: response.data.result.location || "",
          stars_number: response.data.result.stars_number || 0,
          heating_type: response.data.result.heating_type || "",
          subscription_type: response.data.result.subscription_type || 0,
          content: response.data.result.content || "",
          is_visible: response.data.result.is_visible ?? true,
        });
      });

    // Récupération des gateways
    axios
      .get(`${API_URL}/hotels/${id}/gateways`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setGateways(response.data.result || []);
      });
  }, [id, API_URL, token]);

  // useEffect pour récupérer la consommation en fonction du mois et de l'année sélectionnés
  useEffect(() => {
    axios
      .get(`${API_URL}/hotels/${id}/average?month=${selectedMonth}&year=${selectedYear}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setConsumption(response.data.result);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération de la consommation :", error);
      });
  }, [id, API_URL, token, selectedMonth, selectedYear]);

  // Gestion du changement pour le formulaire de sélection du mois et de l'année
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "radio" ? parseInt(value) : value,
    });
  };

  // Gère la création de gateway
  const handleGatewayChange = (e) => {
    const { name, value } = e.target;
    setNewGateway({ ...newGateway, [name]: value });
  };

  const handleCreateGateway = () => {
    axios
      .post(`${API_URL}/gateways`, newGateway, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setGateways([...gateways, response.data.result]);
        setNewGateway({
          internet_type: "",
          hotel_id: id,
          last_seen: new Date().toISOString().slice(0, 19).replace("T", " "),
        });
        navigate(0);
      })
      .catch((error) => {
        console.error("Error creating gateway:", error);
      });
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const handleSubmit = () => {
    axios
      .patch(`${API_URL}/hotels/${id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        navigate(0);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.error("Error updating hotel:", err);
      });
  };

  const handleSyncShowers = () => {
    navigate(`/hotels/${id}/syncShowers`);
  };

  const handleHotelAlerts = () => {
    navigate(`/hotels/${id}/alerts`);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleGatewayModal = () => setIsModalGatewayOpen(!isModalGatewayOpen);

  const showPopup = (message, type) => {
    setPopupMessage(message);
    setPopupType(type);
    setIsPopupVisible(true);
  };

  const closePopup = () => setIsPopupVisible(false);
  const closeConfirmation = () => setIsConfirmationVisible(false);

  const handleSyncHotel = () => setIsConfirmationVisible(true);

  const confirmSyncHotel = () => {
    closeConfirmation();
    axios
      .get(`${API_URL}/hotels/${id}/syncHotelLuniApp`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => showPopup("✅ Hôtel synchronisé avec succès dans LuniApp.", "success"))
      .catch((err) => showPopup(`❌ Erreur lors de la synchronisation : ${err.response?.data?.message || err.message}`, "error"));
  };

  const openEditModal = (index) => {
    setCurrentRoomIndex(index);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentRoomIndex(null);
    navigate(0);
  };

  const saveAndNextRoom = () => {
    if (currentRoomIndex < listeChambre.length - 1) {
      setCurrentRoomIndex(currentRoomIndex + 1);
    } else {
      closeEditModal();
    }
  };

  // Dans votre composant HotelPage.jsx

  // Fonction pour envoyer les averages vers lunidata_v2
  const handleSendAverage = () => {
    axios
      .get(`${API_URL}/hotels/${id}/sendAverage?month=${selectedMonth}&year=${selectedYear}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // Afficher un popup ou mettre à jour un état pour notifier l'utilisateur
        showPopup(response.data.result, "success");
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi des moyennes :", error);
        showPopup(error.response?.data?.message || error.message, "error");
      });
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center justify-between">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate("/")}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>
          <h1 className="text-2xl font-bold">{hotel?.name}</h1>
          <button className="bg-[#365644] text-white px-4 py-2 rounded mb-3" onClick={toggleModal} style={{ marginLeft: "auto" }}>
            Modifier les informations de cet hôtel
          </button>
        </div>

        {/* Sélecteur de mois et d'année */}
        <div className="flex gap-4 items-center mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Mois</label>
            <select value={selectedMonth} onChange={handleMonthChange} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Année</label>
            <input type="number" value={selectedYear} onChange={handleYearChange} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" />
          </div>

          <div className="flex items-end">
            <button className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded transition duration-200" onClick={handleSendAverage}>
              Envoyer Average
            </button>
          </div>
        </div>

        {hotel && <Carrousel hotel={hotel} consumption={consumption} className="mt-4" />}

        <div className="mt-5 flex flex-col">
          <p className="text-lg font-semibold text-black mb-2">Informations concernant l'hôtel</p>
          <span className="bg-gray-200 rounded-lg text-black italic p-4 text-m mb-3">
            AppID : {hotel?.app_id ? hotel?.app_id : "Pas d'appId (pas possible)"}
            <br /> Description : {hotel?.content ? hotel?.content : "Pas de description"}
          </span>

          <div className="flex gap-4 mt-4">
            <button className="bg-blue-900 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition duration-200" onClick={handleSyncHotel}>
              Synchroniser Hôtel avec LuniApp
            </button>
            <button className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg transition duration-200" onClick={handleSyncShowers}>
              Synchroniser Douches avec LuniApp
            </button>
            <button className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg transition duration-200" onClick={handleHotelAlerts}>
              Alertes de l'hôtel
            </button>
          </div>
        </div>

        {isPopupVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className={`p-6 rounded-lg shadow-lg max-w-md w-full ${popupType === "success" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"}`}>
              <h2 className="text-lg font-semibold mb-2">{popupType === "success" ? "✅ Succès" : "❌ Erreur"}</h2>
              <p>{popupMessage}</p>
              <button onClick={closePopup} className="mt-4 w-full bg-gray-800 text-white py-2 rounded hover:bg-gray-900 transition duration-200">
                Fermer
              </button>
            </div>
          </div>
        )}

        {isConfirmationVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-xl font-bold text-gray-800 mb-4">⚠️ Confirmer la synchronisation</h2>
              <p>Êtes-vous sûr de vouloir synchroniser cet hôtel avec LuniApp ?</p>
              <div className="flex justify-end mt-6 gap-4">
                <button className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded-lg" onClick={closeConfirmation}>
                  Annuler
                </button>
                <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg" onClick={confirmSyncHotel}>
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="mt-5 flex justify-around">
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "rooms" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={() => handleFilterClick("rooms")}>
            Liste des chambres
          </button>
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "reserve" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={() => handleFilterClick("reserve")}>
            Réserve
          </button>
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "gateways" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={() => handleFilterClick("gateways")}>
            Etat des gateways
          </button>
        </div>

        <hr className="my-6 border-t-2 border-gray-300 w-full" />
        {activeFilter === "rooms" && (
          <div className="mb-4 mt-4">
            <div className="flex justify-between mb-2">
              <h2 className="font-bold text-lg mb-2">Liste des Chambres</h2>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Numéro de Chambre</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Module équipé</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Type</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Dernier Heartbeat</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">% de Heartbeat</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Alertes Ouvertes</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">{listeChambre.length === 0 ? <div>Aucune chambre disponible pour le moment.</div> : listeChambre.map((chambre, index) => <Room key={index} data={chambre} isAssociated={chambre.has_module == 1} onEdit={() => openEditModal(index)} />)}</tbody>
              </table>
            </div>
            {isEditModalOpen && <EditRoomModal isOpen={isEditModalOpen} onClose={closeEditModal} room={listeChambre[currentRoomIndex]} hotelId={id} onNext={saveAndNextRoom} />}
          </div>
        )}

        {activeFilter === "reserve" && (
          <div className="mb-4 mt-4">
            <div className="flex justify-between mb-2">
              <h2 className="font-bold text-lg mb-2">Modules en Reserve</h2>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">DevEUI</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Version</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">{listeReserve.length === 0 ? <div>Aucun module dans la réserve.</div> : listeReserve.map((module, index) => <Module key={index} data={module} isAssociated={false} />)}</tbody>
              </table>
            </div>
          </div>
        )}

        {activeFilter === "gateways" && (
          <div>
            <div className="flex flex-col items-center justify-center mt-6">
              <div className="bg-white shadow-md rounded-lg overflow-hidden w-full">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">GatewayEUI</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type d'Internet</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Seen</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {gateways.map((gateway) => (
                      <tr key={gateway.id}>
                        <td className="px-6 py-4 whitespace-nowrap">{gateway.gatewayEUI}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{gateway.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{gateway.internet_type}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{gateway.last_seen ? gateway.last_seen : "Never Seen"}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {gateway.state === "ONLINE" && "Online"}
                          {gateway.state === "NEVER_SEEN" && "Never Seen"}
                          {gateway.state === "OFFLINE" && "Offline"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {gateways.length === 0 && <p className="text-center text-gray-500 py-4">Aucune gateway disponible.</p>}
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button className="bg-[#365644] text-white px-4 py-2 rounded" onClick={toggleGatewayModal}>
                Ajouter une gateway
              </button>
            </div>
          </div>
        )}

        {isModalGatewayOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-4">
              <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Ajouter une gateway</h2>
              <button type="button" className="absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl" onClick={toggleGatewayModal} aria-label="Close">
                &times;
              </button>
              <div className="mb-4">
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium mb-2">Nom de la gateway</label>
                  <input type="text" name="name" value={newGateway.name} onChange={handleGatewayChange} className="border border-gray-300 rounded-lg w-full p-2" placeholder="Ex: Gateway1 - B&B Saint-Ouen" />
                </div>
                <label className="block text-gray-700 font-medium mb-2">Type d'Internet</label>
                <select name="internet_type" value={newGateway.internet_type} onChange={handleGatewayChange} className="border border-gray-300 rounded-lg w-full p-2 bg-white focus:outline-none focus:ring-2 focus:ring-[#365644]">
                  <option value="">-- Sélectionner un type --</option>
                  <option value="LTE">LTE</option>
                  <option value="Ethernet">Ethernet</option>
                  <option value="WiFi">WiFi</option>
                </select>
              </div>
              <button onClick={handleCreateGateway} className="bg-[#365644] text-white px-4 py-2 rounded-lg hover:bg-[#2d4737] transition duration-200">
                Ajouter
              </button>
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-4">
              <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Modification de l'hôtel</h2>
              <button type="button" className="absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl" onClick={toggleModal} aria-label="Close">
                &times;
              </button>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Nom de l'hôtel</label>
                  <input type="text" name="name" value={formData.name} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" placeholder={formData.name} />
                </div>
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Adresse de l'hôtel</label>
                  <input type="text" name="location" value={formData.location} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" placeholder={formData.location} />
                </div>
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Nombre d'étoiles</label>
                  <input type="number" name="stars_number" value={formData.stars_number} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" placeholder={formData.stars_number} />
                </div>
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Type de chauffage</label>
                  <input type="text" name="heating_type" value={formData.heating_type} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" placeholder={formData.heating_type} />
                </div>
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Type d'abonnement</label>
                  <div className="flex items-center">
                    <input type="radio" name="subscription_type" value="0" checked={formData.subscription_type === 0} onChange={handleChange} className="mr-2" />
                    <label className="mr-4">Standard</label>
                    <input type="radio" name="subscription_type" value="1" checked={formData.subscription_type === 1} onChange={handleChange} className="mr-2" />
                    <label>Premium</label>
                  </div>
                </div>
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Visible</label>
                  <div className="flex items-center">
                    <input type="radio" name="is_visible" value="1" checked={formData.is_visible === 1} onChange={handleChange} className="mr-2" />
                    <label className="mr-4">Oui</label>
                    <input type="radio" name="is_visible" value="0" checked={formData.is_visible === 0} onChange={handleChange} className="mr-2" />
                    <label>Non</label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Informations supplémentaires</label>
                <input type="text" name="content" value={formData.content} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" placeholder={formData.content} />
              </div>
              <button onClick={handleSubmit} className="w-full bg-[#365644] text-white px-4 py-2 rounded-lg hover:bg-[#2d4737] transition duration-200">
                Enregistrer les modifications
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HotelPage;
