import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { Sidebar } from "../components/Sidebar";
import { AiOutlineArrowLeft } from "react-icons/ai";

function HotelAlertsPage() {
  const { hid } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.user.access_token;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  const [alertsByRoom, setAlertsByRoom] = useState({});
  const [expandedRooms, setExpandedRooms] = useState(new Set());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [hotelAlerts, setHotelAlerts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalShowerData, setModalShowerData] = useState({});

  const fetchAlerts = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/hotels/${hid}/alerts`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const allAlerts = response.data.result;
        console.log(allAlerts);
        const hotelAlerts = allAlerts.filter((alert) => alert.isHotelAlert === 1);
        setHotelAlerts(hotelAlerts);

        const groupedAlerts = {};
        allAlerts
          .filter((alert) => alert.isHotelAlert !== 1)
          .forEach((alert) => {
            const roomNumber = alert.room_nb ? `Chambre ${alert.room_nb}` : `Modules sans Chambre`;
            if (!groupedAlerts[roomNumber]) {
              groupedAlerts[roomNumber] = [];
            }
            groupedAlerts[roomNumber].push(alert);
          });

        setAlertsByRoom(groupedAlerts);
        setExpandedRooms(new Set(Object.keys(groupedAlerts)));
        setLoading(false);
      })
      .catch((err) => {
        setError(`❌ Erreur lors du chargement des alertes : ${err.message}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAlerts();
  }, [hid, API_URL, token]);

  const handleCloseAlert = (alertId, roomNumber) => {
    axios
      .put(`${API_URL}/alerts/${alertId}/close`, {}, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setAlertsByRoom((prevAlerts) => {
          console.log(roomNumber);
          const updatedAlerts = { ...prevAlerts };
          updatedAlerts[roomNumber] = updatedAlerts[roomNumber].map((alert) => (alert.id === alertId ? { ...alert, is_closed: true } : alert));
          return updatedAlerts;
        });
      })
      .catch((err) => setError(`❌ Erreur lors de la fermeture : ${err.message}`));
  };

  const handleCloseAlertHotel = (alertId) => {
    axios
      .put(`${API_URL}/alerts/${alertId}/close`, {}, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        // Mise à jour de l'état après la fermeture de l'alerte
        setHotelAlerts((prevAlerts) => prevAlerts.map((alert) => (alert.id === alertId ? { ...alert, is_closed: true } : alert)));
      })
      .catch((err) => setError(`❌ Erreur lors de la fermeture : ${err.message}`));
  };

  const handleGenerateAlerts = () => {
    setLoading(true);
    axios
      .post(`${API_URL}/hotels/${hid}/generateAlerts`, {}, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setSuccessMessage(`✅ ${response.data.message || "Checkup terminé. Les alertes ont été mises à jour."}`);
        fetchAlerts();
      })
      .catch((err) => setError(`❌ Erreur lors du checkup : ${err.message}`))
      .finally(() => setLoading(false));
  };

  const toggleRoom = (roomNumber) => {
    setExpandedRooms((prevExpandedRooms) => {
      const updatedRooms = new Set(prevExpandedRooms);
      if (updatedRooms.has(roomNumber)) {
        updatedRooms.delete(roomNumber);
      } else {
        updatedRooms.add(roomNumber);
      }
      return new Set(updatedRooms);
    });
  };

  const openEditModal = (alert) => {
    axios
      .get(`${API_URL}/showers/${alert.shower_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const showerData = response.data.result;
        setModalShowerData({
          ...showerData,
          id: alert.id, // Ajout de l'ID de l'alerte pour fermer après update
          room_nb: alert.room_nb,
        });
        setIsModalOpen(true);
      })
      .catch((err) => setError(`❌ Erreur lors du chargement de la douche : ${err.message}`));
  };

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalShowerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateShowerAndCloseAlert = () => {
    axios
      .put(`${API_URL}/showers/${modalShowerData.shower_id}`, modalShowerData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => handleCloseAlert(modalShowerData.id, `Chambre ${modalShowerData.room_nb}`))
      .then(() => {
        setIsModalOpen(false);
        setSuccessMessage("✅ Douche mise à jour et alerte fermée avec succès.");
      })
      .catch((err) => setError(`❌ Erreur lors de la mise à jour : ${err.message}`));
  };

  const handleUpdateLowTempShowers = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/hotels/${hid}/correctLowTempShowers`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setSuccessMessage(`✅ ${response.data.message || "Les douches à basse température ont été mises à jour."}`);

        // Étape 2 : Fermer les alertes de type "SHOWER_LOW_TEMP"
        const showerLowTempAlerts = [];

        // Récupérer les alertes qui correspondent à "SHOWER_LOW_TEMP"
        Object.values(alertsByRoom).forEach((alerts) => {
          alerts.forEach((alert) => {
            if (alert.alert_type === "SHOWER_LOW_TEMP" && !alert.is_closed) {
              showerLowTempAlerts.push(alert.id);
            }
          });
        });

        if (showerLowTempAlerts.length > 0) {
          // Fermer toutes les alertes SHOWER_LOW_TEMP
          return Promise.all(showerLowTempAlerts.map((alertId) => axios.put(`${API_URL}/alerts/${alertId}/close`, {}, { headers: { Authorization: `Bearer ${token}` } })));
        }
      })
      .then(() => {
        setSuccessMessage(`✅ Les douches à basse température ont été corrigées et les alertes SHOWER_LOW_TEMP fermées.`);
        fetchAlerts(); // Recharge les alertes après mise à jour
      })
      .catch((err) => setError(`❌ Erreur lors de la mise à jour des douches : ${err.message}`))
      .finally(() => setLoading(false));
  };

  const handleUpdateLowVolumeShowers = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/hotels/${hid}/correctLowVolumeShowers`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setSuccessMessage(`✅ ${response.data.message || "Les douches à bas volume ont été mises à jour."}`);

        // Étape 2 : Fermer les alertes de type "SHOWER_LOW_TEMP"
        const showerLowVolumeAlerts = [];

        // Récupérer les alertes qui correspondent à "SHOWER_LOW_TEMP"
        Object.values(alertsByRoom).forEach((alerts) => {
          alerts.forEach((alert) => {
            if (alert.alert_type === "SHOWER_LOW_VOLUME" && !alert.is_closed) {
              showerLowVolumeAlerts.push(alert.id);
            }
          });
        });

        if (showerLowVolumeAlerts.length > 0) {
          // Fermer toutes les alertes SHOWER_LOW_TEMP
          return Promise.all(showerLowVolumeAlerts.map((alertId) => axios.put(`${API_URL}/alerts/${alertId}/close`, {}, { headers: { Authorization: `Bearer ${token}` } })));
        }
      })
      .then(() => {
        setSuccessMessage(`✅ Les douches à bas volume ont été corrigées et les alertes SHOWER_LOW_TEMP fermées.`);
        fetchAlerts(); // Recharge les alertes après mise à jour
      })
      .catch((err) => setError(`❌ Erreur lors de la mise à jour des douches : ${err.message}`))
      .finally(() => setLoading(false));
  };

  const memoizedAlertsByRoom = useMemo(() => {
    return Object.entries(alertsByRoom).sort((a, b) => {
      const numA = parseInt(a[0].replace(/\D/g, ""), 10);
      const numB = parseInt(b[0].replace(/\D/g, ""), 10);

      if (isNaN(numA)) return 1;
      if (isNaN(numB)) return -1;

      return numA - numB;
    });
  }, [alertsByRoom]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center justify-between">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>
          <h1 className="text-2xl font-bold" style={{ marginRight: "auto" }}>
            🚨 Liste des alertes de l'hôtel
          </h1>
        </div>

        <button className="mt-4 bg-[#365644] text-white px-4 py-3 rounded-lg hover:bg-[#2d4737] transition" onClick={handleGenerateAlerts} disabled={loading}>
          🩺 Lancer un checkup pour générer des alertes
        </button>

        <button className="mt-4 ml-4 bg-[#3910B5] text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition" onClick={handleUpdateLowTempShowers} disabled={loading}>
          ❄️ Corriger les douches à basse température
        </button>

        <button className="mt-4 ml-4 bg-[#3910B5] text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition" onClick={handleUpdateLowVolumeShowers} disabled={loading}>
          🚿 Corriger les douches à bas Volume (sauf Témoin !)
        </button>

        {successMessage && <p className="text-green-600 mt-4">{successMessage}</p>}
        {loading && <p>Chargement des alertes...</p>}
        {error && <p className="text-red-600">{error}</p>}

        {!loading && Object.keys(alertsByRoom).length === 0 && <p className="text-green-600 mt-4">✅ Aucune alerte détectée pour cet hôtel.</p>}

        {!loading && hotelAlerts.length > 0 && (
          <div className="mt-6 bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-semibold text-red-600">🚨 Alertes générales de l'hôtel</h2>
            <AlertTable alerts={hotelAlerts} handleCloseAlert={handleCloseAlertHotel} />
          </div>
        )}

        {!loading &&
          memoizedAlertsByRoom.map(([roomNumber, alerts]) => (
            <div key={roomNumber} className="mt-6 bg-white shadow rounded-lg p-6">
              <button onClick={() => toggleRoom(roomNumber)} className="text-lg font-semibold w-full text-left flex justify-between">
                {roomNumber} {alerts.length > 0 && alerts[0].module_type ? `${alerts[0].module_type}` : ""} <span>{expandedRooms.has(roomNumber) ? "🔽 Cacher" : "▶️ Afficher"}</span>
              </button>

              {expandedRooms.has(roomNumber) && <MemoizedAlertTable alerts={alerts} handleCloseAlert={handleCloseAlert} openEditModal={openEditModal} roomNumber={roomNumber} />}
            </div>
          ))}

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">✏️ Modifier la douche</h2>
              <div>Volume (L)</div>
              <input type="number" name="volume" value={modalShowerData.volume} onChange={handleModalChange} placeholder="Volume (L)" className="border w-full p-2 rounded mb-3" />
              <div>Température</div>
              <input type="number" name="temperature" value={modalShowerData.temperature} onChange={handleModalChange} placeholder="Température (°C)" className="border w-full p-2 rounded mb-3" />
              <div>Objectif</div>
              <input type="number" name="objective" value={modalShowerData.objective} onChange={handleModalChange} placeholder="Objectif (L)" className="border w-full p-2 rounded mb-3" />
              <div>Interaction</div>
              <input type="number" name="interaction" value={modalShowerData.interaction} onChange={handleModalChange} placeholder="Interaction" className="border w-full p-2 rounded mb-3" />
              <div className="flex justify-end space-x-3">
                <button className="bg-gray-400 text-white px-4 py-2 rounded" onClick={() => setIsModalOpen(false)}>
                  Annuler
                </button>
                <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={handleUpdateShowerAndCloseAlert}>
                  💾 Enregistrer
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const MemoizedAlertTable = React.memo(AlertTable);

// 📋 Table réutilisable pour les alertes
function AlertTable({ alerts, handleCloseAlert, openEditModal, roomNumber }) {
  return (
    <table className="w-full text-left border-collapse mt-4">
      <thead>
        <tr className="bg-gray-200">
          <th className="p-3">#ID</th>
          <th className="p-3">🔔 Type d'alerte</th>
          <th className="p-3">📏 Seuil</th>
          <th className="p-3">📊 Valeur actuelle</th>
          <th className="p-3">📊 Occurrences</th>
          <th className="p-3">📡 Module</th>
          <th className="p-3">📡 Douche</th>
          <th className="p-3">⏰ Date</th>
          {handleCloseAlert && <th className="p-3">🔒 Actions</th>}
        </tr>
      </thead>
      <tbody>
        {alerts.map((alert) => (
          <tr key={alert.id} className={alert.is_closed ? "bg-green-100" : "bg-red-100"}>
            <td className="p-3">{alert.id}</td>
            <td className="p-3">{alert.alert_type}</td>
            <td className="p-3">{alert.threshold_value}</td>
            <td className="p-3">{alert.actual_value}</td>
            <td className="p-3">{alert.occurrences}</td>
            <td className="p-3">{alert.module_id ? `Module ID ${alert.module_id}` : "—"}</td>
            <td className="p-3">{alert.shower_id ? `Shower ID ${alert.shower_id}` : "Pas une alerte de douche"}</td>
            <td className="p-3">{new Date(alert.timestamp).toLocaleString()}</td>
            <td className="p-3">
              {!alert.is_closed && (
                <div className="flex gap-2">
                  {alert.shower_id && (
                    <button className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition" onClick={() => openEditModal(alert)}>
                      ✏️ Modifier
                    </button>
                  )}
                  <button className="bg-[#ccbf28] text-white px-3 py-1 rounded hover:bg-[#dad435] transition" onClick={() => handleCloseAlert(alert.id, roomNumber)}>
                    🔒 Fermer
                  </button>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default HotelAlertsPage;
