import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { Sidebar } from "../components/Sidebar";
import { AiOutlineArrowLeft } from "react-icons/ai";

function SyncShowersPage() {
  const { hId } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.user.access_token;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  const [chambres, setChambres] = useState([]);
  const [error, setError] = useState("");
  const [selectedShowers, setSelectedShowers] = useState([]);
  const [expandedRooms, setExpandedRooms] = useState(new Set());
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const correctTemp = 40;
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionMode, setSelectionMode] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalShowerData, setModalShowerData] = useState({});

  // 🔄 1️⃣ Récupérer toutes les douches avec moyennes depuis le nouvel endpoint
  useEffect(() => {
    axios
      .get(`${API_URL}/hotels/${hId}/showersToSend`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        const roomsData = Object.entries(response.data.result).map(([roomNumber, data]) => ({
          roomNumber,
          avgVolume: data.avgVolume,
          avgTemp: data.avgTemp,
          passingShowers: data.passingShowers,
          failingShowers: data.failingShowers,
          isTemoin: data.isTemoin,
        }));
        setChambres(roomsData);
        setExpandedRooms(new Set(roomsData.map((room) => room.roomNumber)));
        setSelectedShowers(roomsData.flatMap((room) => room.passingShowers.map((shower) => shower.shower_id)));
      })
      .catch((err) => setError(`❌ Erreur lors du chargement : ${err.message}`));
  }, [hId, API_URL, token]);

  useEffect(() => {
    const handleMouseUp = () => setIsSelecting(false);

    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const toggleRoom = (roomNumber) => {
    const newExpandedRooms = new Set(expandedRooms);
    newExpandedRooms.has(roomNumber) ? newExpandedRooms.delete(roomNumber) : newExpandedRooms.add(roomNumber);
    setExpandedRooms(newExpandedRooms);
  };

  // Gestion modification d'une douche

  const openEditModal = (shower) => {
    setModalShowerData(shower);
    setIsModalOpen(true);
  };

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalShowerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateShower = () => {
    axios
      .put(`${API_URL}/showers/${modalShowerData.shower_id}`, modalShowerData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        setChambres((prevChambres) =>
          prevChambres.map((room) => ({
            ...room,
            passingShowers: room.passingShowers.map((shower) => (shower.shower_id === modalShowerData.shower_id ? modalShowerData : shower)),
            failingShowers: room.failingShowers.map((shower) => (shower.shower_id === modalShowerData.shower_id ? modalShowerData : shower)),
          }))
        );
        setIsModalOpen(false);
        // showPopup("✅ Données de la douche mises à jour avec succès.", "success");
      })
      .catch((err) => showPopup(`❌ Erreur lors de la mise à jour : ${err.message}`, "error"));
  };

  const handleCheckboxChange = (showerId) => {
    setSelectedShowers((prevSelected) => (prevSelected.includes(showerId) ? prevSelected.filter((id) => id !== showerId) : [...prevSelected, showerId]));
  };

  // 📤 3️⃣ Envoyer les douches sélectionnées
  const handleSendSelectedShowers = () => {
    const allShowersList = chambres.flatMap((room) => [...room.passingShowers, ...room.failingShowers]);
    const formattedShowers = allShowersList.map((shower) => ({
      shower_id: shower.shower_id,
      checked: selectedShowers.includes(shower.shower_id),
    }));

    axios
      .post(`${API_URL}/hotels/${hId}/sendSelectedShowersToLuniApp`, { showers: formattedShowers }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        showPopup(`✅ ${response.data.message}`, "success");
        setSelectedShowers([]);
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.error || err.message;
        // console.log(err);
        showPopup(`❌ Erreur lors de l'envoi : ${errorMsg}`, "error");
      });
  };

  const handleFixZeroTemperatureForShower = (roomNumber, showerId) => {
    const updatedRooms = chambres.map((room) => {
      if (room.roomNumber === roomNumber) {
        const updateShowerTemp = (showers) => showers.map((shower) => (shower.shower_id === showerId && shower.temperature === 0 ? { ...shower, temperature: correctTemp } : shower));

        return {
          ...room,
          passingShowers: updateShowerTemp(room.passingShowers),
          failingShowers: updateShowerTemp(room.failingShowers),
        };
      }
      return room;
    });
    setChambres(updatedRooms);
  };

  const handleFixAllZeroTemperatures = () => {
    setChambres((prevChambres) =>
      prevChambres.map((room) => ({
        ...room,
        passingShowers: room.passingShowers.map((shower) => (shower.temperature === 0 ? { ...shower, temperature: correctTemp } : shower)),
        failingShowers: room.failingShowers.map((shower) => (shower.temperature === 0 ? { ...shower, temperature: correctTemp } : shower)),
      }))
    );
  };

  const handleMouseDownOnCheckbox = (showerId) => {
    setIsSelecting(true);
    const isCurrentlySelected = selectedShowers.includes(showerId);
    setSelectionMode(isCurrentlySelected ? "deselect" : "select");
    toggleShowerSelection(showerId, isCurrentlySelected);
  };

  const handleMouseOverOnCheckbox = (showerId) => {
    if (isSelecting) {
      const isCurrentlySelected = selectedShowers.includes(showerId);
      if ((selectionMode === "select" && !isCurrentlySelected) || (selectionMode === "deselect" && isCurrentlySelected)) {
        toggleShowerSelection(showerId, isCurrentlySelected);
      }
    }
  };

  const toggleShowerSelection = (showerId, isCurrentlySelected) => {
    setSelectedShowers((prevSelected) => (isCurrentlySelected ? prevSelected.filter((id) => id !== showerId) : [...prevSelected, showerId]));
  };

  const showPopup = (message, type) => {
    setPopupMessage(message);
    setPopupType(type);
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    navigate(0);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center justify-between">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>
          <h1 className="text-2xl font-bold" style={{ marginRight: "auto" }}>
            Synchronisation des douches avec LuniApp
          </h1>
        </div>

        {error && <p className="text-red-600 mb-2">{error}</p>}

        <div className="mt-5 flex flex-col">
          <div className="space-y-4">
            <button className={`w-full mt-6 bg-[#365644] text-white px-4 py-3 rounded-lg hover:bg-[#2d4737] transition ${selectedShowers.length === 0 ? "opacity-50 cursor-not-allowed" : ""}`} onClick={handleSendSelectedShowers} disabled={selectedShowers.length === 0}>
              🚀 Envoyer les douches sélectionnées ({selectedShowers.length})
            </button>

            <button className={`bg-[#ccbf28]  text-white px-4 py-2 rounded-lg hover:bg-[#dad435] transition w-full mb-4 ${selectedShowers.length === 0 ? "opacity-50 cursor-not-allowed" : ""}`} onClick={handleFixAllZeroTemperatures}>
              🌡️ Corriger toutes les douches à 0°C à {correctTemp}°C
            </button>

            {chambres.map(({ roomNumber, avgVolume, avgTemp, passingShowers, failingShowers, isTemoin }) => (
              <div key={roomNumber} className="border rounded-lg p-4 shadow-md bg-gray-50">
                <button onClick={() => toggleRoom(roomNumber)} className="text-lg font-semibold w-full text-left flex justify-between">
                  🛏️ Chambre {roomNumber} {isTemoin && "TEMOIN"} <span>{expandedRooms.has(roomNumber) ? "🔽 Cacher" : "▶️ Afficher"}</span>
                </button>

                <p className="text-sm text-gray-700 mt-2 italic">
                  📊 Moyenne Volume LuniApp : <strong>{avgVolume} L</strong> | 🌡️ Moyenne Température LuniApp : <strong>{avgTemp}°C</strong>
                </p>

                {expandedRooms.has(roomNumber) && (
                  <ul className="mt-4">
                    {[...passingShowers, ...failingShowers].map((shower) => (
                      <li key={shower.shower_id} style={{ userSelect: "none" }} className={`mb-2 p-2 rounded cursor-pointer ${selectedShowers.includes(shower.shower_id) ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}`} onMouseDown={() => handleMouseDownOnCheckbox(shower.shower_id)} onMouseOver={() => handleMouseOverOnCheckbox(shower.shower_id)} onChange={() => handleCheckboxChange(shower.shower_id)}>
                        <label className="flex items-center space-x-3">
                          <input type="checkbox" checked={selectedShowers.includes(shower.shower_id)} onChange={() => handleCheckboxChange(shower.shower_id)} />
                          <span>
                            🚿 <strong>ID :</strong> {shower.shower_id} | 💧 <strong>Volume :</strong> {shower.volume} L | 🌡️ <strong>Temp :</strong> {shower.temperature}°C
                          </span>
                        </label>
                        {shower.temperature === 0 && (
                          <button onClick={() => handleFixZeroTemperatureForShower(roomNumber, shower.shower_id)} className="bg-[#ccbf28] text-white px-2 py-1 rounded hover:bg-[#dad435] transition">
                            🌡️ Corriger à {correctTemp}°C
                          </button>
                        )}
                        <button className="bg-[#4f53e0] text-white px-3 py-1 rounded hover:bg-[#3035db] transition" onClick={() => openEditModal(shower)}>
                          ✏️ Modifier
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>

          <button className={`w-full mt-6 bg-[#365644] text-white px-4 py-3 rounded-lg hover:bg-[#2d4737] transition ${selectedShowers.length === 0 ? "opacity-50 cursor-not-allowed" : ""}`} onClick={handleSendSelectedShowers} disabled={selectedShowers.length === 0}>
            🚀 Envoyer les douches sélectionnées ({selectedShowers.length})
          </button>

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">✏️ Modifier la douche</h2>
                <div>Volume (L)</div>
                <input type="number" name="volume" value={modalShowerData.volume} onChange={handleModalChange} placeholder="Volume (L)" className="border w-full p-2 rounded mb-3" />
                <div>Température</div>
                <input type="number" name="temperature" value={modalShowerData.temperature} onChange={handleModalChange} placeholder="Température (°C)" className="border w-full p-2 rounded mb-3" />
                <div>Objectif</div>
                <input type="number" name="objective" value={modalShowerData.objective} onChange={handleModalChange} placeholder="Objectif (L)" className="border w-full p-2 rounded mb-3" />
                <div>Interaction</div>
                <input type="number" name="interaction" value={modalShowerData.interaction} onChange={handleModalChange} placeholder="Interaction" className="border w-full p-2 rounded mb-3" />
                <div className="flex justify-end space-x-3">
                  <button className="bg-gray-400 text-white px-4 py-2 rounded" onClick={() => setIsModalOpen(false)}>
                    Annuler
                  </button>
                  <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={handleUpdateShower}>
                    💾 Enregistrer
                  </button>
                </div>
              </div>
            </div>
          )}

          {isPopupVisible && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className={`p-6 rounded-lg shadow-lg max-w-md w-full ${popupType === "success" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"}`}>
                <h2 className="text-lg font-semibold mb-2">{popupType === "success" ? "✅ Succès" : "❌ Erreur"}</h2>
                <p>{popupMessage}</p>
                <button onClick={closePopup} className="mt-4 w-full bg-gray-800 text-white py-2 rounded hover:bg-gray-900 transition duration-200">
                  Fermer
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SyncShowersPage;
