import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { EditRoomModal } from "./EditRoomModal";
import { AssociateModuleModal } from "./AssociateModuleModal";
import { useAuth } from "react-oidc-context";

export function Room({ data, isAssociated, onEdit }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModifModalOpen, setIsModifModalOpen] = useState(false);
  const [isAssociateModalOpen, setIsAssociateModalOpen] = useState(false);
  const menuRef = useRef(null);
  const [menuDirectionUp, setMenuDirectionUp] = useState(false);

  const roomId = data.room_id || data.id;
  const hotelId = data.hotel_id;

  const auth = useAuth();
  // console.log(auth);
  const token = auth.user.access_token;
  // console.log(token);
  // const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  function toggleModifModal() {
    setIsModifModalOpen(!isModifModalOpen);
  }

  function toggleAssociateModal() {
    setIsAssociateModalOpen(!isAssociateModalOpen);
  }

  const handleMenuClick = (e) => {
    e.stopPropagation();

    // Récupérer la position du bouton "•••"
    const buttonRect = e.target.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const menuHeight = 150; // Estimation de la hauteur du menu (ajuste si nécessaire)

    // Si le menu dépasse en bas de la fenêtre, on l'affiche vers le haut
    if (buttonRect.bottom + menuHeight > windowHeight) {
      setMenuDirectionUp(true);
    } else {
      setMenuDirectionUp(false);
    }

    // Ouvrir le menu après avoir défini la direction
    setMenuOpen((prev) => !prev);
  };

  const dissociateRoomModule = (e) => {
    e.stopPropagation();

    axios
      .put(
        `${API_URL}/hotels/${hotelId}/rooms/${roomId}/dissociate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        navigate(0);
      })
      .catch((err) => {
        console.error("Erreur dissocier module:", err);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getTimeSince = (date) => {
    if (!date) return "Aucun";
    const now = new Date();
    const past = new Date(date);
    const diffInMs = now - past;
    const diffInMinutes = Math.floor(diffInMs / 60000);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 1) return "À l'instant";
    if (diffInMinutes < 60) return `${diffInMinutes} minute(s)`;
    if (diffInHours < 24) return `${diffInHours} heure(s)`;
    return `${diffInDays} jour(s)`;
  };

  return (
    <>
      <tr
        onClick={() => {
          navigate(`/hotels/${hotelId}/rooms/${roomId}`, {
            state: { hotel_id: hotelId, id: roomId },
          });
        }}
        className="cursor-pointer hover:bg-gray-50 relative"
      >
        <td>{data.room_nb}</td>
        <td>{data.has_module ? "Oui" : "Non"}</td>
        <td>{data.config_mode}</td>
        <td>{getTimeSince(data.last_heartbeat_date)}</td>
        <td>{data.heartbeat_coverage_percentage}</td>
        <td>{data.has_open_alerts ? "🚨" : "✅"}</td>
        <td className=" px-6 py-4 whitespace-nowrap text-right">
          <button onClick={handleMenuClick} className="text-gray-500 hover:text-gray-700">
            •••
          </button>
        </td>
        {menuOpen && (
          <div ref={menuRef} className={`absolute right-0 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10 ${menuDirectionUp ? "bottom-full mb-2" : "mt-2"}`}>
            <ul>
              {isAssociated ? (
                <li onClick={dissociateRoomModule} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Désassocier Module
                </li>
              ) : (
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleAssociateModal();
                  }}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  Associer Module
                </li>
              )}

              <li
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-blue-600"
              >
                Modifier chambre
              </li>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  console.log(`Supprimer chambre id : ${roomId}`);
                }}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-600"
              >
                Supprimer chambre
              </li>
            </ul>
          </div>
        )}
      </tr>

      {/* <EditRoomModal isOpen={isModifModalOpen} onClose={toggleModifModal} room={data} hotelId={hotelId} /> */}
      <AssociateModuleModal isOpen={isAssociateModalOpen} onClose={toggleAssociateModal} hotelId={hotelId} roomId={roomId} />
    </>
  );
}
