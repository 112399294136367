import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

export function EditRoomModal({ isOpen, onClose, room, hotelId, onNext }) {
  const navigate = useNavigate();

  const [listeEtages, setListeEtages] = useState();
  const [formData, setFormData] = useState({
    room_nb: "",
    is_lunishower: 1,
    is_pmr: 0,
    nb_places: 0,
    floor_id: 0,
  });

  const roomId = room.room_id || room.id;

  const auth = useAuth();
  const token = auth.user.access_token;
  // const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  useEffect(() => {
    if (isOpen && room) {
      setFormData({
        room_nb: room.room_nb || "",
        is_lunishower: room.is_lunishower || 1,
        is_pmr: room.is_pmr || 0,
        nb_places: room.nb_places || 0,
        floor_id: room.floor_id || 0,
      });
    }

    axios
      .get(`${API_URL}/hotels/${hotelId}/floors`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const resultFloors = response.data.result;
        setListeEtages(resultFloors);
      });
  }, [isOpen, room]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "radio" ? parseInt(value) : value,
    });
  };

  const handleSubmit = () => {
    if (!formData.room_nb) {
      alert("Veuillez entrer un numéro de chambre.");
      return;
    }

    axios
      .patch(`${API_URL}/hotels/${hotelId}/rooms/${roomId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        navigate(0);
      })
      .catch((err) => {
        console.error("Error updating room:", err);
      });
  };

  const handleSubmitNext = () => {
    axios
      .patch(`${API_URL}/hotels/${hotelId}/rooms/${roomId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => onNext()) // Passer à la chambre suivante après mise à jour
      .catch((err) => console.error("Error updating room:", err));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-4">
        <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Modification de la chambre</h2>
        <button type="button" className="absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl" onClick={onClose} aria-label="Close">
          &times;
        </button>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Numéro de chambre</label>
            <input type="text" name="room_nb" value={formData.room_nb} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" placeholder="Numéro de la chambre" />
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Nombre de places</label>
            <input type="number" name="nb_places" value={formData.nb_places} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" placeholder="Nombre de places" />
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Étage</label>
            <select name="floor_id" value={formData.floor_id} onChange={handleChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black">
              <option value="">Sélectionner un étage</option>
              {listeEtages?.map((etage) => (
                <option key={etage.id} value={etage.id}>
                  {etage.floor_number}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">LuniShower</label>
            <div className="flex items-center">
              <input type="radio" name="is_lunishower" value="1" checked={formData.is_lunishower === 1} onChange={handleChange} className="mr-2" />
              <label className="mr-4">Oui</label>
              <input type="radio" name="is_lunishower" value="0" checked={formData.is_lunishower === 0} onChange={handleChange} className="mr-2" />
              <label>Non</label>
            </div>
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">PMR</label>
            <div className="flex items-center">
              <input type="radio" name="is_pmr" value="1" checked={formData.is_pmr === 1} onChange={handleChange} className="mr-2" />
              <label className="mr-4">Oui</label>
              <input type="radio" name="is_pmr" value="0" checked={formData.is_pmr === 0} onChange={handleChange} className="mr-2" />
              <label>Non</label>
            </div>
          </div>
        </div>
        <button onClick={handleSubmit} className="w-full bg-[#7faa6d] text-white px-4 py-2 rounded-lg hover:bg-[#346840] transition duration-200">
          Enregistrer
        </button>
        <button onClick={handleSubmitNext} className="w-full bg-[#365644] text-white px-4 py-2 rounded-lg hover:bg-[#2d4737] transition duration-200">
          Enregistrer et suivante
        </button>
      </div>
    </div>
  );
}
